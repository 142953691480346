import styled from "styled-components"
import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const CurvedBorderImage = styled(Img)`
border-radius: 20px;
align-self: center;
width: 100%;
order: ${props => (props.$liftOnMobile ? -1 : 0)};

@media (min-width: 480px) {
  width: 50%;
}

@media (min-width: 768px) {
  order: 0;
  flex-basis: 500%;
  width: 100%;
}

@media (min-width: 1100px) {
  flex-basis: 250%;
}

@media (min-width: 1500px) {
  flex-basis: 100%;
}
`

const SideImage = ({ fluid, liftOnMobile }) => {
  return (
    <>
      <CurvedBorderImage $liftOnMobile={liftOnMobile} durationFadeIn={0} fluid={fluid} />
    </>
  )
}

SideImage.propTypes = {
  fluid: PropTypes.object,
  liftOnMobile: PropTypes.bool
}

SideImage.defaultProps = {
  liftOnMobile: false,
}

export default SideImage
