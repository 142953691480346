import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import TestimonialGiver from "./testimonial_giver"
import SideImage from "./side_image"

const TestimonialsWrapper = styled.div`
  margin-top: 1rem;
`

const Testimonial = styled.div`
`

const QuoteBlock = styled.div`
  margin: 1rem 0;
  padding: 0 1rem;
  border-left: solid;
  border-left-color: ${({ theme }) => theme.colors.secondaryOrangeLight};
`

const QuoteText = styled.p`
  font-style: italic;
`

const TestimonialsList = styled.div`
display: flex;
row-gap: 2rem;
flex-direction: column;
`

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  column-gap: 2rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const Testimonials = ({ title, testimonials, accessoryImage }) => {
  
  const testies = testimonials.map((testimonial, index) => {
    return (
      <Testimonial key={index}>
        <QuoteBlock>
          <QuoteText>{testimonial.quote}</QuoteText>
        </QuoteBlock>
        <TestimonialGiver
          personName={testimonial.person}
          personTitle={testimonial.title}
        />
      </Testimonial>
    )
  })
  return (
    <TestimonialsWrapper>
      <h2>{title}</h2>
      <Container>
        <TestimonialsList>
          {testies}
        </TestimonialsList>
        <SideImage fluid={accessoryImage} liftOnMobile={true} />
      </Container>
    </TestimonialsWrapper>
  )
}

Testimonials.propTypes = {
  title: PropTypes.string,
  testimonials: PropTypes.arrayOf(PropTypes.object),
  accessoryImage: PropTypes.object
}

Testimonials.defaultProps = {
  title: `Working with Christine`,
}

export default Testimonials
