import styled from "styled-components"
import React from "react"
import PropTypes from "prop-types"
import SideImage from "../side_image"

const TeamMemberWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;

@media(min-width: 768px) {
    flex-direction: row;
    gap: 4rem;
}
`

const TextWrapper = styled.div`
`

const Name = styled.h3``

const Title = styled.h4`
  color: ${({ theme }) => theme.colors.gray};
`

const NameTitle = styled.div`
  padding: 1rem 0;
`

const Bio = styled.p`
  padding-bottom: 1rem;
`

const TeamMember = ({ name, title, bioItems, image, idx }) => {
  
  const bioParagraphs = bioItems.map((item, idx2) => {
    return (<Bio key={idx2}>{item}</Bio>)
  })
  
  
  return (
    <TeamMemberWrapper key={idx}>
      <SideImage durationFadeIn="0" fluid={image.fluid} />
      <TextWrapper>
        <NameTitle>
          <Name>{name}</Name>
          {/* <Title>{title}</Title> */}
        </NameTitle>
        {bioParagraphs}
      </TextWrapper>
    </TeamMemberWrapper>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  bioItems: PropTypes.array,
  image: PropTypes.object,
  idx: PropTypes.number
}

const Header = styled.h2`
  padding: 1rem;
  margin: 0 auto;
`

const RosterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;  
`

const Roster = ({ people }) => {
  const teamMembers = people.map((person, idx) => {
    return (
      <TeamMember
        name={person.name}
        title={person.title}
        bioItems={person.bioItems}
        image={person.image}
        key={idx}
      />
    )
  })

  return (
    <RosterWrapper>
      <Header>About Your Lead Consultant</Header>
      {teamMembers}
    </RosterWrapper>
  )
}

Roster.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      bioItems: PropTypes.array,
      image: PropTypes.object
    })
  ),
}

export default Roster
