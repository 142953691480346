import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import Explainer from "../components/Explainer/Explainer"
import Divider from "../components/Divider/Divider"
import Roster from "../components/Roster/Roster"
import Testimonials from "../components/testimonials"
import Main from "../components/main"

export const query = graphql`
  {
    companyYaml {
      mission_and_vision
    }
    annieImage: file(relativePath: { eq: "annie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    christineInAction: file(relativePath: { eq: "christine2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    people: allPeopleYaml {
      edges {
        node {
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          name
          bio_items
        }
      }
    }
    testimonials: allTestimonialsYaml(filter: { tags: { in: "about" } }) {
      edges {
        node {
          person
          quote
          title
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const people = data.people.edges.map(el => {
    const person = el.node
    return {
      name: person.name,
      title: person.title,
      bioItems: person.bio_items,
      image: person.image.childImageSharp,
    }
  })

  return (
    <>
      <SEO title="About" />
      <Hero title="About" backgroundImageName="about" />
      <Main>
        <Explainer
          title="About Wheels on the Bus, LLC"
          image={data.annieImage.childImageSharp}
          text={data.companyYaml.mission_and_vision}
        />
        <Divider />
        <Roster people={people} />
        <Divider />
        <Testimonials
          title="Working with Christine"
          testimonials={data.testimonials.edges.map(el => el.node)}
          accessoryImage={data.christineInAction.childImageSharp.fluid}
        />
      </Main>
    </>
  )
}

export default AboutPage
