import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const PersonName = styled.p`
  font-weight: bold;
`

const TestimonialGiver = ({ personName, personTitle }) => (
    <>
        <PersonName>{personName}</PersonName>
        <p>{personTitle}</p>
    </>
)

TestimonialGiver.propTypes = {
  personName: PropTypes.string,
  personTitle: PropTypes.string
}

export default TestimonialGiver



