import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import SideImage from "../side_image"

const ExplainerWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 4rem;
  }
`

const TextWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
`

const H2 = styled.h2`
  padding-bottom: 1rem;
`

const Blurb = styled.p`
  padding-bottom: 1rem;
`

const Explainer = ({ title, image, text }) => {
  return (
    <ExplainerWrapper>
      <TextWrapper>
        <H2>{title}</H2>
        <Blurb>{text}</Blurb>
      </TextWrapper>
      <SideImage fluid={image.fluid} />
    </ExplainerWrapper>
  )
}

Explainer.defaultProps = {
  title: `Wheels On The Bus Consulting`,
  text: ``
}

Explainer.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object
}

export default Explainer
